@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

#root, main {
    height: 100vh
}

@font-face {
    font-family: "TuesdayNight";
    src: local("TuesdayNight"),
    url("../fonts/TuesdayNight/TuesdayNight-Regular.otf") format("opentype");
}
